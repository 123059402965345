import { template as template_81f3f03407f04e2c859e54f3373c3f4d } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_81f3f03407f04e2c859e54f3373c3f4d(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
