import { template as template_9e746f6444fe4e0099cfb4c8210035e6 } from "@ember/template-compiler";
const FKControlMenuContainer = template_9e746f6444fe4e0099cfb4c8210035e6(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
